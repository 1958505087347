<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="15.016" height="17.291" viewBox="0 0 15.016 17.291">
        <path id="Polígono_17" data-name="Polígono 17" d="M8.645,0l8.645,15.016H0Z" transform="translate(15.016) rotate(90)" :fill="fill"/>
    </svg>
</template>

<script>
export default {
    name: 'IconTriangleRigthPrimary',
    props: {
        fill: {
            type: String,
            default: '#00305a'
        }
    }
}
</script>