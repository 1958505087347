<template>
	<div :class="variant">
	</div>
</template>

<script>
export default {
	name: 'ComponentDivider',
	props: {
		variant: {
			type: String,
			default: 'c-divider'
		}
	},
}
</script>

<style lang="scss" scoped>
.c-divider {
	display: flex;
	background-color: $alfa-third;
	border-radius: 80px;
	height: 4px;
	width: 100%;
}

.c-dividerSecondary {
	display: flex;
	background-color: $alfa-secondary;
	border-radius: 80px;
	width: 133px;
	height: 2px;
	margin-top: 32px;
}
</style>