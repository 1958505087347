<template>
  <div class="c-text-typography">
    <div v-if="tag === 'title'">
      <h1 :class="['c-text-typography__wrapper__title', baseClasses, { 'c-text-typography__title-divider': divider }]">
        <slot />
      </h1>
      <span v-if="divider" class="c-text-typography__divider">
        <Divider></Divider>
      </span>
      <span v-else-if="dividerSecondary" class="c-text-typography__dividerSecondary">
        <Divider :variant="'c-dividerSecondary'"></Divider>
      </span>
    </div>

    <div v-else-if="tag === 'subtitle'">
      <span v-if="icon" id="c-text-typography__wrapper__icon" class="c-text-typography__wrapper__icon">
        <IconTriangleRigthPrimary :fill="iconColor" />
      </span>
      <h2 v-else-if="tag === 'subtitle'" :class="[
      'c-text-typography__subtitle',
      baseClasses,
      '',
      { 'c-text-typography__title-divider': divider },
      { 'c-text-typography__personalization': icon },
    ]">
        <slot />
      </h2>
      <span v-if="divider" class="c-text-typography__divider">
        <Divider></Divider>
      </span>
      <span v-else-if="dividerSecondary" class="c-text-typography__dividerSecondary">
        <Divider :variant="'c-dividerSecondary'"></Divider>
      </span>
    </div>
    <div v-else-if="tag === 'secondary-subtitle'">
      <h3
        :class="['c-text-typography__secondary-subtitle', baseClasses, { 'c-text-typography__title-divider': divider }]">
        <slot />
      </h3>
      <span v-if="divider" class="c-text-typography__divider">
        <Divider></Divider>
      </span>
      <span v-else-if="dividerSecondary" class="c-text-typography__dividerSecondary">
        <Divider :variant="'c-dividerSecondary'"></Divider>
      </span>
    </div>

    <h3 v-else-if="tag === 'subtitle-articles'" class="c-text-typography__subtitle-articles">
      <slot />
    </h3>

    <h3 v-else-if="tag === 'highlight-secondary'"
      :class="[`c-text-typography__highlight-secondary`, baseClasses, highlightText ? `c-text-typography__${tag}--highlight-text` : '']">
      <slot />
    </h3>

    <h3 v-else-if="tag === 'subsections-subtitle'"
      :class="[
        `c-text-typography__subsections-subtitle`,
        highlightText ? `c-text-typography__${tag}--highlight-text` : '',
        baseClasses,
        { 'c-text-typography__title-divider': divider },
      ]"
    >
      <slot :class="baseClasses" />
      <span v-if="divider" class="c-text-typography__divider">
        <Divider></Divider>
      </span>
    </h3>

    <h3 v-else-if="tag === 'text-menu'"
      :class="[
        `c-text-typography__text-menu`,
        highlightText ? `c-text-typography__${tag}--highlight-text` : '',
        baseClasses,
        { 'c-text-typography__title-divider': divider },
      ]"
    >
      <slot :class="baseClasses" />
      <span v-if="divider" class="c-text-typography__divider">
        <Divider></Divider>
      </span>
    </h3>

    <h4 v-else-if="tag === 'text'"
      :class="[
        `c-text-typography__text`,
        highlightText ? `c-text-typography__${tag}--highlight-text` : '',
        baseClasses,
        { 'c-text-typography__title-divider': divider },
      ]"
    >
      <slot :class="baseClasses" />
      <span v-if="divider" class="c-text-typography__divider">
        <Divider></Divider>
      </span>
    </h4>

    <h2 v-else :class="[
      `c-text-typography__${tag}`,
      highlightText ? `c-text-typography__${tag}--highlight-text` : '',
      baseClasses,
      { 'c-text-typography__title-divider': divider },
    ]">
      <slot :class="baseClasses" />
      <span v-if="divider" class="c-text-typography__divider">
        <Divider></Divider>
      </span>
    </h2>
  </div>
</template>

<script>
import iconTriangleLeftPrimary from '~/components/shared/icons/IconTriangleLeftPrimary.vue'

export default {
  name: 'TextTypography',
  components: {
    iconTriangleLeftPrimary,
  },

  data() {
    return {
      isSmallScreen: false,
    }
  },

  props: {
    tag: {
      type: String,
      default: 'text',
      validator(value) {
        return [
          'title',
          'subtitle',
          'secondary-subtitle',
          'subsections-subtitle',
          'hp-secondary-subtitle',
          'text',
          'text-menu',
          'text-support',
        ].includes(value)
      },
    },
    highlightText: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: '#00305a',
    },
    divider: {
      type: Boolean,
      default: false,
    },
    dividerSecondary: {
      type: Boolean,
      default: false,
    },
    separator: {
      type: Boolean,
      default: false,
    },
    separatormobile: {
      type: Boolean,
      default: false,
    },
    separatorcenter: {
      type: Boolean,
      default: false,
    },
    fontColor: {
      type: String,
      default: '',
    },
    additionalColor: {
      type: String,
      default: '',
    },
  },

  mounted() {
    this.checkScreenWidth()
    window.addEventListener('resize', this.checkScreenWidth)
  },

  beforeMount() {
    window.removeEventListener('resize', this.checkScreenWidth)
  },

  methods: {
    checkScreenWidth() {
      this.isSmallScreen = window.innerWidth <= 1279
    },
  },

  computed: {
    baseClasses() {
      return [
        this.fontColor ? `alfa-${this.fontColor}` : '',
        this.isSmallScreen && this.additionalColor ? this.additionalColor : '',
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.c-text-typography {
  display: flex;

  &__wrapper {
    position: relative;
    align-items: center;
    display: flex;
    gap: 8px;

    &__title {
      @include font($montserrat-bold, 25, 3.5502vw, 65);
      line-height: normal;
    }

    &__icon {
      position: absolute;
      top: 7px;

      svg {
        width: 100%;
        height: 100%;
        min-width: 15px;
        min-height: 25px;
        max-width: 32px;
        max-height: 31px;
      }
    }
  }

  &__title-divider {
    padding-bottom: 4px;
  }

  &__divider {
    width: auto;
    margin-top: 5px;
  }

  &__dividerSecondary {
    display: flex;
    justify-content: center;
    width: auto;
  }

  &__subtitle {
    position: relative;
    @include font($montserrat-semi-bold, 28, 2.6826vw, 46);
    line-height: 38.44px;

    @media (min-width: $tablet) {
      line-height: 40px;
    }
  }

  &__secondary-subtitle {
    @include font($montserrat-semi-bold, 28, 2.4224vw, 41);
    line-height: 38.44px;

    @media (min-width: $tablet) {
      line-height: 40px;
    }
  }

  &__subsections-subtitle {
    @include font($montserrat-semi-bold, 22, 2.468vw, 44);
  }

  &__subtitle-articles {
    @include font($montserrat-semi-bold, 28, 1.9137vw, 32);
    line-height: 38.92px;
  }

  &__highlight-secondary {
    @include font($montserrat-semi-bold, 20, 2.1854vw, 28);
    line-height: 34.17px;
  }

  &__personalization {
    padding-left: 30px;
  }

  @media (min-width: $tablet) {
    line-height: 27px;
  }

  &__text,
  &__text-menu {
    @include font($montserrat-regular, 18, 1.5123vw, 25);
    line-height: 27px;
    width: 100%;
    overflow-wrap: break-word;

    @media (min-width: 1450px) {
      line-height: 38.44px;
      overflow-wrap: normal;
    }

    &--highlight-text {
      @include font($montserrat-semi-bold, 18, 1.5123vw, 25);
      line-height: 27px;
    }

    &--medium-text {
      @include font($montserrat-medium, 18, 1.5123vw, 25);
      line-height: 27px;
    }
  }

  &__text-v2 {
    @include font($montserrat-regular, 18, 1.5123vw, 25);
    line-height: 27px;

    @media (min-width: 1450px) {
      line-height: 38.44px;
    }
  }

  &__text-support {
    font-size: 16px;
    line-height: 21px;

    @media (min-width: $tablet) {
      font-size: 22.78px;
      line-height: 21px;
    }
  }

  .alfa-white {
    color: $alfa-white;
  }

  .alfa-primary {
    color: $alfa-primary;
  }

  .alfa-secondary {
    color: $alfa-secondary;
  }

  .alfa-third {
    color: $alfa-third;
  }

  .alfa-default {
    color: $alfa-default-text;
  }

  .alfa-success {
    color: $alfa-success;
  }

  .alfa-danger {
    color: $alfa-danger;
  }

  .--highlight-text {
    font-weight: 800;
    @include font($montserrat-bold, 18, 1.5123vw, 25);
  }

  @media (max-width: 1279px) {
    .--center-mobile {
      text-align: center;
      width: 100%;
    }
  }
}
</style>
